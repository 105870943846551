import type { alert as o365_alert } from 'o365-vue-services ';

import O365TokenHandler from './data.authentication.O365TokenHandler.ts';
import context from './context.ts';

export interface IGroupedRequest {
    requestId: string;
    request: Request;
    resolve: (value: Response) => void;
    reject: (reason?: any) => void;
}

const groupedRequestContainer = new Array<IGroupedRequest>();

let groupedRequestInterval = 10;
let groupedRequestMaxGroupCount = 100;

/* Testing, to be removed. */
const groupedRequestIntervalTesting = localStorage.getItem('groupedRequestInterval');
if(groupedRequestIntervalTesting != null){
    groupedRequestInterval = parseInt(groupedRequestIntervalTesting);
}
const groupedRequestMaxGroupCountTesting = localStorage.getItem('groupedRequestMaxGroupCount');
if(groupedRequestMaxGroupCountTesting != null){
    groupedRequestMaxGroupCount = parseInt(groupedRequestMaxGroupCountTesting);
}
/* Testing, to be removed. */

setInterval(async () => {
    let groupedRequestsToHandle = new Array<IGroupedRequest>();

    while (groupedRequestContainer.length > 0 && groupedRequestsToHandle.length < groupedRequestMaxGroupCount) {
        let groupedRequest = groupedRequestContainer.pop();

        if (groupedRequest === undefined) {
            continue;
        }

        if (groupedRequest.request.signal.aborted) {
            groupedRequest.reject(groupedRequest.request.signal.reason);
            continue;
        }

        groupedRequestsToHandle.push(groupedRequest);
    }

    if (groupedRequestsToHandle.length === 0) {
        return;
    }

    let newBody = [];
    let databaseObjectName = [];

    for (let groupedRequest of groupedRequestsToHandle) {
        try {
            let clonedRequest = groupedRequest.request.clone();
            let bodyJson = await clonedRequest.json();
            
            bodyJson.requestId = groupedRequest.requestId;

            newBody.push(bodyJson);

            switch (bodyJson.operation ?? bodyJson.Operation) {
                case 'execute':
                    databaseObjectName.push(bodyJson.ProcedureName);
                    break;
                case 'retrieve':
                    databaseObjectName.push(bodyJson.dataSourceId);
                    break;
            }
        } catch (reason) {
            groupedRequest.reject(reason);
        }
    }

    try {
        let baseUrl = '/api/data/group';
        let queryString = `?resource-names=${databaseObjectName.join(';')}`.substring(0, 2048);

        let response = await fetch(baseUrl + queryString, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newBody)
        });

        if (response.status !== 200 && response.status !== 207) {
            for (let groupedRequest of groupedRequestsToHandle) {
                groupedRequest.resolve(response);
            }
            
            return;
        }

        let responseJson = await response.json();

        if (!responseJson.success) {
            for (let groupedRequest of groupedRequestsToHandle) {
                groupedRequest.reject(responseJson);
            }

            return;
        }
        
        for (let reponseEntry of responseJson.success) {
            let groupedRequest = groupedRequestsToHandle.find((groupedRequest) => groupedRequest.requestId === reponseEntry.requestId);

            if (groupedRequest === undefined) {
                continue;
            }

            if (groupedRequest.request.signal.aborted) {
                groupedRequest.reject(groupedRequest.request.signal.reason);
            }

            groupedRequest.resolve(new Response(JSON.stringify(reponseEntry), { status: reponseEntry.responseCode ?? (reponseEntry.success ? 200 : 500), headers: response.headers}));
        }
    } catch (reason) {
        for (let groupedRequest of groupedRequestsToHandle) {
            groupedRequest.reject(reason);
        }
    }
}, groupedRequestInterval);

function alert(...args: Parameters<typeof o365_alert>) {
    return import('o365-vue-services').then(services => {
        return services.alert(...args);
    });
}

namespace API {
    type Method = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH';
    type ResponseStatusHandler = (response: Response, options: IRequestOptions) => Promise<Response>;

    export enum ResponseHandler {
        JSON = 'JSON',
        HTML = 'HTML',
        Text = 'TEXT',
        Stream = 'STREAM',
        Raw = 'RAW'
    }

    export class APIError extends Error {
        errorType : number;
        skipVueHandler = true;
        
        constructor(pMessage : string, pErrorType : number) {
            super(pMessage);
            this.errorType = pErrorType;
        }
    }

    interface IRequestOptions {
        requestInfo: RequestInfo,
        method?: Method,
        headers?: Headers | { [key: string]: string },
        body?: BodyInit,
        mode?: RequestMode,
        credentials?: RequestCredentials,
        cache?: RequestCache,
        redirect?: RequestRedirect,
        integrity?: string,
        keepalive?: boolean,
        abortSignal?: AbortSignal,
        responseStatusHandler?: Boolean | ResponseStatusHandler,
        responseBodyHandler?: Boolean | ResponseHandler,
        showErrorDialog?: Boolean,
        skipJwtCheck?: Boolean
    }

    export async function requestPost(url: string, body?: BodyInit, abortSignal?: AbortSignal, credentials: RequestCredentials = 'same-origin'): Promise<any> {
        return await request({
            requestInfo: url,
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-NT-API': 'true'
            }),
            credentials: credentials,
            body: body,
            abortSignal: abortSignal
        });
    }

    export async function requestGet(url: string, pOptions?: Partial<IRequestOptions>): Promise<any> {
        return await request({
            requestInfo: url,
            method: 'GET',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-NT-API': 'true'
            }),
            showErrorDialog: pOptions?.showErrorDialog
        });
    }

    export async function requestHtml(url: string, body?: BodyInit, method: Method = 'GET'): Promise<Document> {
        return await request({
            requestInfo: url,
            method: method,
            headers: new Headers({
                'Accept': 'text/html',
                'Content-Type': 'application/json',
                'X-NT-API': 'true'
            }),
            body: body,
            responseBodyHandler: ResponseHandler.HTML
        });
    }

    export async function requestText(url: string, body?: BodyInit, method: Method = 'GET'): Promise<string> {
        return await request({
            requestInfo: url,
            method: method,
            headers: new Headers({
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'X-NT-API': 'true'
            }),
            body: body,
            responseBodyHandler: ResponseHandler.Text
        });
    }

    export async function requestStream(url: string, body?: BodyInit, method: Method = 'POST', credentials: RequestCredentials = 'same-origin'): Promise<ReadableStreamDefaultReader<Uint8Array>> {
        return await request({
            requestInfo: url,
            method: method,
            headers: new Headers({
                'Accept': 'application/stream+json',
                'Content-Type': 'application/json',
                'X-NT-API': 'true'
            }),
            credentials: credentials,
            body: body,
            responseBodyHandler: ResponseHandler.Stream
        });
    }

    export async function requestFile(url:string,body:any){
        return fetch(url,{
            method:body?"POST":"GET",
            body:body?JSON.stringify(body):null,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                'X-NT-API': 'true'
            }
        }).then(async response =>{
             if (response.status === 200) {
                return await response.blob();
            } else {
                throw Error(await response.text());
            }
        })
    }

    export async function requestFileCustomHeaders(url:string,body:any, headers:any){
        return fetch(url,{
            method:body?"POST":"GET",
            body:body?body:null,
            headers: headers
        }).then(async response =>{
             if (response.status === 200) {
                return await response.blob();
            } else {
                throw Error(await response.text());
            }
        })
    }


    export async function request(options: IRequestOptions): Promise<any> {
        try {
            const headers = options.headers;

            if (headers == undefined) {
                options.headers = new Headers({'X-NT-API': 'true'});
            } else if (headers instanceof Headers) {
                if (headers.has('X-NT-API') === false) {
                    headers.append('X-NT-API', 'true')
                } else if (headers.get('X-NT-API') === 'false') {
                    headers.delete('X-NT-API');
                }
            } else if (headers.hasOwnProperty('X-NT-API') === false) {
                headers['X-NT-API'] = 'true';
            } else if (headers['X-NT-API'] === 'false') {
                delete headers['X-NT-API'];
            }

            const request = await _generateRequest(options);
            
            var response: Response | null;

            if (request.headers.has('X-O365-GROUP-REQUEST')) {
                response = await _sendGroupedRequest(request);
            } else {
                response = await fetch(request);
            }

            const responseStatusHandler = options.responseStatusHandler ?? true;

            if (typeof responseStatusHandler === 'boolean' && responseStatusHandler === true) {
                response = await _defaultResponseStatusHandler(response, options);
            } else if (typeof responseStatusHandler === 'function') {
                response = await responseStatusHandler(response, options);
            }

            if (response?.headers.has('X-Deprecated')) {
                console.warn(response.headers.get('X-Deprecated'));
            }

            if (response === null || options.responseBodyHandler === false || response.status === 204) {
                return response;
            }

            let data: any;

            switch (options.responseBodyHandler ?? ResponseHandler.JSON) {
                case ResponseHandler.JSON:
                    data = await _defaultResponseJsonBodyHandler(response);
                    break;
                case ResponseHandler.HTML:
                    data = await _defaultResponseHtmlBodyHandler(response);
                    break;
                case ResponseHandler.Text:
                    data = await _defaultResponseTextBodyHandler(response);
                    break;
                case ResponseHandler.Stream:
                    data = await _defaultResponseStreamBodyHandler(response);
                    break;
                case ResponseHandler.Raw:
                default:
                    data = response;
                    break;
            }

            return data;
        } catch (error: any) {
            // ignoreerror on safai when a request was interupted by redirect.
            // workflowID: 1009062
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            
            if (isSafari && error == "TypeError: Load failed") {
                return;
            }

            if (options.showErrorDialog ?? true) {
                alert(error);
            }

            throw error;
        }
    }

    async function _sendGroupedRequest(request: Request): Promise<Response> {
        return new Promise((resolve, reject) => {
            var groupedRequestObject = <IGroupedRequest>{ requestId: crypto.randomUUID(), request, resolve, reject }

            groupedRequestContainer.push(groupedRequestObject);
        });
    }

    // ---- Generate Request based on options ---- //

    // Request
    async function _generateRequest(options: IRequestOptions): Promise<Request> {
        const requestVerificationTokenInputs = document.querySelectorAll('input[name=__RequestVerificationToken]');

        let requestHasHandler = false;
        
        if (options.requestInfo instanceof Request) {
            requestHasHandler = !!new URL(options.requestInfo.url, window.location.toString()).searchParams.get('handler');

            const requestVerificationTokenInput = requestVerificationTokenInputs[0] as HTMLInputElement;
            const requestVerificationToken = requestVerificationTokenInput.value;

            options.requestInfo.headers.append('RequestVerificationToken', requestVerificationToken);

            return options.requestInfo;
        }

        requestHasHandler = !!new URL(options.requestInfo, window.location.toString()).searchParams.get('handler');

        if (requestVerificationTokenInputs.length === 1 && requestHasHandler) {
            const requestVerificationTokenInput = requestVerificationTokenInputs[0] as HTMLInputElement;
            const requestVerificationToken = requestVerificationTokenInput.value;

            const headers = options.headers ?? new Headers();

            if (headers instanceof Headers) {
                headers.append('RequestVerificationToken', requestVerificationToken);
            } else if (typeof headers === 'object') {
                headers['RequestVerificationToken'] = requestVerificationToken;
            }

            options.headers = headers;
        }

        if (!(options.skipJwtCheck ?? false)) {
            const jwt = await O365TokenHandler.getToken();

            if (jwt !== null) {
                const headers = options.headers ?? new Headers();

                if (headers instanceof Headers && !headers.has('Authorization')) {
                    headers.set('Authorization', `Bearer ${jwt.access_token}`);
                } else if (typeof headers === 'object' && !(headers as {[key: string]: string;}).hasOwnProperty('Authorization')) {
                    (headers as {[key: string]: string;})['Authorization'] = `Bearer ${jwt.access_token}`;
                }

                options.headers = headers;
            }
        }

        var headers = options.headers;

        try {
            if (headers instanceof Headers && !headers.has('X-O365-CONTEXT-ID')) {
                headers.set('X-O365-CONTEXT-ID', context.id?.toString());
            } else if (typeof headers === 'object' && !(headers as {[key: string]: string;}).hasOwnProperty('X-O365-CONTEXT-ID')) {
                (headers as {[key: string]: string;})['X-O365-CONTEXT-ID'] = context.id?.toString();
            } else {
                options.headers = {
                    'X-O365-CONTEXT-ID': context.id?.toString()
                }
            }
        } catch (ex) {
            console.log(ex);
        }

        const request = new Request(options.requestInfo,
            {
                method: options.method,
                headers: options.headers,
                body: _generateRequestBody(options),
                mode: options.mode,
                credentials: options.credentials,
                cache: options.cache,
                redirect: options.redirect,
                integrity: options.integrity,
                keepalive: options.keepalive,
                signal: options.abortSignal
            });

        return request;
    }

    // Request body
    function _generateRequestBody(options: IRequestOptions): BodyInit | null {
        if ([undefined, null, 'GET', 'DELETE', 'OPTIONS', 'HEAD', 'TRACE', 'CONNECT'].includes(options.method)) {
            return null;
        }

        if (options.body && options.body.constructor === FormData) {
            return options.body;
        }

        const body = options.body;

        if (typeof body === 'object') {
            try {
                return JSON.stringify(body);
            } catch (error) {
                console.warn('Failed to parse body');
            }
        }

        return body ?? null;
    }

    // ---- Response status code verification ---- //

    async function _defaultResponseStatusHandler(response: Response, options: IRequestOptions): Promise<Response | null> {
        if (response.redirected) {
            window.location.href = response.url;
            return null;
        }

        switch (response.status) {
            case 401: // Unauthorized
                var location = response.headers.get('location');
                if(!location) location = '/login';
                var result = await _defaultUnauthorizedHandler(options, location);
                return result ? await _defaultResponseStatusHandler(result, options) : null;
            case 409: // Conflict
                await _defaultConflictHandler(response);
                break;
            case 502: // Bad Gateway
            case 503: // Service Unavailable
            case 504: // Gateway Timeout
                _defaultServiceUnavailableHandler(response.status);
                break;
        }

        return response;
    } 

    function _isBehindProxy() : boolean{
        return (<HTMLMetaElement>document.querySelector("[name=o365-proxy-request]"))?.content === 'true';
    }

    /**
     * 401 - Unauthorized  
     * Initiates the login dialog and afterwards returns a response for a fetch request from the given options
     */
    async function _defaultUnauthorizedHandler(options: IRequestOptions, url:string): Promise<Response | null> {
        if(_isBehindProxy()){     
            var isMfa = url === '/login/mfa';
            url = '/login?ReturnUrl=%2fapi/sessionexpired/remove';
            if(isMfa){
                url += '&RequireTwoFactor=1'
            }
        }

        var loginNode = document.getElementById('loginFrame');
        if(loginNode === null){
            loginNode = document.createElement('iframe');
            loginNode.id = 'loginFrame';
            loginNode.src = url;
            loginNode.classList.add('session-expired-container');
            loginNode.style.zIndex = '2000';
            loginNode.style.width = '100%';
            loginNode.style.height = '100%';
            loginNode.style.position = 'fixed';
            loginNode.style.top = '0';
            document.body.append(loginNode);
        }        

        await observeElementRemoval(loginNode.id);

        // const { handleLogin } = await import('o365.modules.Login.LoginHandler.js');
        // await handleLogin();

        const request = await _generateRequest(options);
        var response: Response | null = await fetch(request);

        return response;
    }

    function observeElementRemoval(elementId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const targetNode = document.getElementById(elementId);

            if (!targetNode) {
                reject(new Error('Element not found'));
                return;
            }

            const config = { childList: true,  };

            const callback = function (mutationsList, observer) {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childRemoved') {
                        observer.disconnect(); // Stop observing once the element is removed
                        resolve('Element removed successfully');
                        return;
                    }
                    if (mutation.type === 'childList') {
                        const removedNodes = mutation.removedNodes;
                        for (const removedNode of removedNodes) {
                            if (removedNode.id === elementId) {
                                observer.disconnect();
                                resolve('Iframe removed successfully');
                                return;
                            }
                        }
                    }
                }
            };

            const observer = new MutationObserver(callback);
            observer.observe(document.body, config);
        });
    }

    // 409 - Conflict
    async function _defaultConflictHandler(response: Response): Promise<void> {
        const jsonResponse = await response.json();

        throw ({
            error: jsonResponse.error,
            status: 409
        })
    }

    // 502 - Bad Gateway
    // 503 - Service Unavailable
    // 504 - Gateway Timeout
    function _defaultServiceUnavailableHandler(statusCode: number) {
        _apiAlert('Could not reach server. Please try again later')
        throw ({
            error: "Could not reach server. Please try again later",
            status: statusCode
        });
    }

    // ---- Response body parsing ---- //

    // JSON
    async function _defaultResponseJsonBodyHandler(response: Response): Promise<any> {
        try {
            let responseBody: any = undefined;

            const contentType = response.headers.get('Content-Type');

            if (contentType === null) {
                try {
                    const responseBodyString = await response.text();

                    responseBody = JSON.parse(responseBodyString);
                } catch (reason) {
                    console.info(reason);
                    return response;
                }
            } else if (!contentType.includes('application/json')) {
                return response;
            }

            if (responseBody === undefined) {
                responseBody = await response.json();
            }


            if (!responseBody) { return responseBody; }

            if (responseBody.error) {
                throw new APIError(responseBody.error, responseBody.errorType);
            }

            if (responseBody.success !== undefined) {
                if (responseBody.success.Result) {
                    return responseBody.success.Result;
                } else {
                    return responseBody.success;
                }
            } else {
                return responseBody;
            }
        } catch (ex) {
            if (ex instanceof APIError) {
                throw ex;
            }

            console.error(ex);

            throw new APIError($t('Oops something went wrong'), 500);
        }
    }

    // HTML
    async function _defaultResponseHtmlBodyHandler(response: Response): Promise<Document> {
        const responseBody = await response.text();
        const domParser = new DOMParser();
        const html = domParser.parseFromString(responseBody, 'text/html');

        return html;
    }

    // Text
    async function _defaultResponseTextBodyHandler(response: Response): Promise<string> {
        const responseBody = await response.text();

        return responseBody;
    }

    // Stream
    async function _defaultResponseStreamBodyHandler(response: Response): Promise<ReadableStreamDefaultReader<Uint8Array> | null> {
        const responseBody = response.body?.getReader();

        return responseBody ?? null;
    }

    // ---- Helpers ---- //

    /**
     * Attempt to show an alert, only 1 alert will be shown at a time
     */
    async function _apiAlert(message: string) {
        try {
            if (_apiAlertIsShown) { return; }
            _apiAlertIsShown = true;
            alert(message);
            window.setTimeout(() => {
                _apiAlertIsShown = false;
            }, 100);
        } catch (_ex) {
            console.error(message);
        }
    }
}

let _apiAlertIsShown = false;

export default API;
